<template>
  <!-- Modal for Notifications and Info -->

  <div
    class="notify modal fade bd-example-modal-lg"
    id="myMessagesModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="title"></div>

        <div
          class="message"
          v-for="(notify, index) in notificationsObj"
          :key="index"
        >
          <div class="row">
            <div class="col col-12">
              <div class="date">
                <h1>{{ notify.date }}</h1>
              </div>
            </div>

            <div class="col col-1 col-lg-1 col-md-1">
              <div class="logo">
                <img src="../../assets/Images/notificationBell.png" alt="" />
              </div>
            </div>

            <div class="col col-10 col-lg-10 col-md-10">
              <div class="txt">
                <h1>
                  {{ notify.message }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal for Notifications and Info End -->
</template>

<script>
export default {
  data() {
    return {
      notificationsObj: [
        {
          id: 0,
          date: "03-19-2021",
          message:
            "Produkti juaj me kodin #NSH134493250043 është nisur për dërgesë dhe së shpejti do të arrij sot në mes orës 13:12PM dhe 14:12PM.",
        },
        {
          id: 1,
          date: "05-29-2021",
          message:
            "Produkti juaj me kodin #NSH0093250043 është nisur për dërgesë dhe së shpejti do të arrij sot në mes orës 15:00PM dhe 16:00PM.",
        },
      ],
    };
  },
};
</script>
