import homepage from "./components/DashHome/homepage.vue";
import notfound from "./components/NotFound/404.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store/index";

Vue.use(VueRouter);

const npostomatPay = (resolve) => {
    require.ensure(["./components/Npay/npostomat-pay.vue"], () => {
        resolve(require("./components/Npay/npostomat-pay.vue"));
    });
};

const ordersPage = (resolve) => {
    require.ensure(["./components/Details/ordersPage.vue"], () => {
        resolve(require("./components/Details/ordersPage.vue"));
    });
};

const detailsPage = (resolve) => {
    require.ensure(["./components/Details/detailsPage.vue"], () => {
        resolve(require("./components/Details/detailsPage.vue"));
    });
};

const routeDetails = (resolve) => {
    require.ensure(["./components/Details/routeDetails.vue"], () => {
        resolve(require("./components/Details/routeDetails.vue"));
    });
};

const myprofile = (resolve) => {
    require.ensure(["./components/OtherPages/my-profile.vue"], () => {
        resolve(require("./components/OtherPages/my-profile.vue"));
    });
};

const rateDriver = (resolve) => {
    require.ensure(["./components/RatingDriver/rate-driver.vue"], () => {
        resolve(require("./components/RatingDriver/rate-driver.vue"));
    });
};

const routeDriver = (resolve) => {
    require.ensure(["./components/RatingDriver/routeDriver.vue"], () => {
        resolve(require("./components/RatingDriver/routeDriver.vue"));
    });
};

const npostomatNetwork = (resolve) => {
    require.ensure(["./components/OtherPages/npostomat-network.vue"], () => {
        resolve(require("./components/OtherPages/npostomat-network.vue"));
    });
};

const login = (resolve) => {
    require.ensure(["./components/Login/login.vue"], () => {
        resolve(require("./components/Login/login.vue"));
    });
};

const guestRoute = (resolve) => {
    require.ensure(["./components/GuestMode/guest-route.vue"], () => {
        resolve(require("./components/GuestMode/guest-route.vue"));
    });
};

const router = new VueRouter({
    mode: "history",
    routes: [{
            path: "/",
            component: homepage,
            meta: { requiresAuth: true },
        },

        {
            path: "/porosite",
            component: routeDetails,
            meta: { requiresAuth: true },

            children: [
                { path: "", component: ordersPage, meta: { requiresAuth: true } },
                { path: ":id", component: detailsPage, meta: { requiresAuth: true } },
            ],
        },

        { path: "/profili", component: myprofile, meta: { requiresAuth: true } },

        {
            path: "/vleresimi-sherbimit",
            component: routeDriver,
            meta: { requiresAuth: true },
            children: [
                { path: ":id", component: rateDriver, meta: { requiresAuth: true } },
            ],
        },

        {
            path: "/rrjetinpostomateve",
            component: npostomatNetwork,
            meta: { requiresAuth: true },
        },

        {
            path: "/guest/porosia/:barcode",
            component: guestRoute,
            props: (route) => ({ query: route.query.access_token }),
        },

        {
            path: "/npay",
            component: npostomatPay,
        },

        { path: "*", component: notfound },
        { path: "/kycu", component: login, meta: { requiresUnauth: true } },
    ],
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((route) => route.meta.requiresAuth === true)) {
        if (!store.getters.isAuthenticated) {
            next({ path: "/kycu" });
        } else {
            next();
        }
    } else if (to.matched.some((route) => route.meta.requiresUnauth === true)) {
        if (store.getters.isAuthenticated) {
            next("/");
        }
    }
    next();
});

export default router;