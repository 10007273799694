<template>
  <div id="app">
    <!-- PreLoader 
    <div class="preloader" id="preloader">
      <img src="../../assets/Images/spinner.svg" alt="" />
    </div>
     PreLoader -->

    <!-- Navigation -->
    <nav class="navbar flex-md-nowrap p-0 shadow " id="myHeader">
      <router-link
        to="/"
        class="navbar-brand col-sm-3 col-md-3 mr-0 col-lg-2"
        href="#"
      >
        <img src="../../assets/Images/npost-logo-white.png" alt=""
      /></router-link>

      <ul class="navbar-nav px-3" id="ulList">
        <li class="nav-item text-nowrap imageToggle">
          <router-link to="/">
            <img src="../../assets/Images/npost-logo-white.png" alt=""
          /></router-link>
        </li>
        <li class="nav-item text-nowrap btnToggle">
          <button
            @click="toggleSidebar()"
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div id="wrapper2">
              <div class="circle2 icon" id="burger">
                <span class="line2 top"></span>
                <span class="line2 middle"></span>
                <span class="line2 bottom"></span>
              </div>
            </div>
          </button>
        </li>
      </ul>
    </nav>

    <div class="container-fluid">
      <div class="row">
        <nav
          class="col-md-3 col-lg-2 d-none d-md-block sidebar   animate__animated"
          id="sidebar"
        >
          <div class="sideOverlay" @click="toggleSidebar()"></div>
          <div class="sidebar-sticky">
            <h6
              class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
            >
              <span>Tabela</span>
            </h6>
            <ul class="nav flex-column">
              <li
                class="nav-item"
                v-for="(menu, index) in mainMenu"
                :key="index"
              >
                <router-link :to="menu.link" class="nav-link" :href="menu.link">
                  <span v-html="menu.icon"></span>
                  {{ menu.name }}
                  <span class="sr-only">(current)</span>
                </router-link>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#"
                  data-toggle="modal"
                  data-target=".bd-example-modal-lg"
                >
                  <i class="far fa-flag"></i> Njoftimet
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#">
                  <i class="fas fa-info"></i> Info
                </a>
              </li>
            </ul>

            <h6
              class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
            >
              <span>Të dhënat</span>
            </h6>
            <ul class="nav flex-column mb-2">
              <li
                class="nav-item"
                v-for="(scmenu, index) in secondMenu"
                :key="index"
                @click="toggleSidebar()"
              >
                <router-link class="nav-link" :to="scmenu.link">
                  <span v-html="scmenu.icon"></span> {{ scmenu.name }}
                </router-link>
              </li>
              <li class="nav-item">
                <a href="#" @click="logout()" class="nav-link"
                  ><i class="fas fa-sign-out-alt"></i> Dilni nga llogaria</a
                >
              </li>
            </ul>
          </div>
        </nav>
        <!-- Navigation End -->

        <main role="main" class="mainClass col-md-9 ml-sm-auto col-lg-10">
          <slot></slot>
          <myMessages></myMessages>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import myMessages from "../../components/Notifications/notifications.vue";
import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;
export default {
  data() {
    return {
      mainMenu: [
        { name: "Kryefaqja", icon: '<i class="fas fa-home"></i>', link: "/" },
        {
          name: "Porositë",
          icon: '<i class="fas fa-box-open"></i>',
          link: "/porosite",
        },
        {
          name: "Kërko NPostomat",
          icon: '<i class="fas fa-search-location"></i>',
          link: "#",
        },
      ],
      secondMenu: [
        {
          name: "Profili im",
          icon: '<i class="far fa-user"></i>',
          link: "/profili",
        },
      ],
    };
  },
  components: {
    myMessages: myMessages,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/kycu");
    },

    toggleSidebar() {
      var sidebar = document.getElementById("sidebar");
      const mq = window.matchMedia("(max-device-width: 767px)");
      if (mq.matches) {
        if (sidebar.classList.contains("d-none")) {
          sidebar.classList.add("animate__fadeInRight");
          sidebar.classList.remove("animate__fadeOutRight");
          sidebar.classList.remove("d-none");
          document.body.style["overflow-y"] = "hidden";
          jQuery(".icon").addClass("close");
        } else {
          sidebar.classList.remove("animate__fadeInRight");
          sidebar.classList.add("animate__fadeOutRight");
          setTimeout(() => {
            sidebar.classList.add("d-none");
            document.body.style["overflow-y"] = "auto";
          }, 1000);
          jQuery(".icon").removeClass("close");
        }
      }
    },
  },
};
</script>
