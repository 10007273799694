<template>
  <dashboard>
    <div class="homepageDiv">
      <div class="container  animate__animated animate__fadeInRight">
        <!-- Greet user -->
        <div class="notifyDiv">
          <div class="divTxt">
            <h1>
              <span>Përshëndetje {{ myEmail }}</span
              >, mirësevini përsëri!
            </h1>
          </div>

          <div class="deliveryNotify">
            <img src="../../assets/Images/notificationBell.png" alt="" />
            <h1>
              Produkti juaj me kodin <b>#NSH134493250043</b> është nisur për
              dërgesë dhe së shpejti do të arrij sot në mes orës 13:12PM dhe
              14:12PM.
            </h1>
          </div>
        </div>
        <!-- Greet user End -->

        <!-- Containers -->
        <div class="row">
          <div
            class="col col-6 col-lg-4 col-md-6"
            v-for="(myorder, index) in myOrders"
            :key="index.myOrder"
          >
            <router-link :to="myorder.link">
              <div class="mainDiv">
                <div class="mainBody">
                  <div class="txt">
                    <h1>
                      <span v-html="myorder.icon"></span> {{ myorder.title }}
                    </h1>
                  </div>
                  <div class="flexDiv">
                    <div class="fx">
                      <h1>Të nisura</h1>
                      <h1 id="notifynumber">( {{ myorder.teNisura }} )</h1>
                    </div>
                    <div class="sc">
                      <button>></button>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>

          <div
            class="col col-6 col-lg-4 col-md-6"
            v-for="(myContainer, index) in myContainers"
            :key="index.myContainer"
          >
            <router-link :to="myContainer.link">
              <div class="mainDiv">
                <div class="mainBody">
                  <div class="txt">
                    <h1>
                      <span v-html="myContainer.icon"></span>
                      {{ myContainer.title }}
                    </h1>
                  </div>
                  <div class="flexDiv dv">
                    <div class="sc mp">
                      <button>></button>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <!-- Container End -->

        <!-- <div class="container">
       <h1>Tokeni</h1>
     <p> {{ this.$store.getters.token }}</p>
     <h1> {{ this.$store.getters.isAuthenticated }}</h1>
     </div> -->
      </div>
    </div>
  </dashboard>
</template>

<script>
import Dashboard from "./dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  data() {
    return {
      myEmail: localStorage.getItem("email"),
      myOrders: [
        {
          title: "Porositë e mija",
          teNisura: 1,
          link: "/porosite",
          icon: '<i class="fas fa-box-open"></i>',
        },
      ],
      myContainers: [
        {
          title: "Profili im",
          link: "/profili",
          icon: '<i class="fas fa-user"></i>',
        },
        {
          title: "Rrjeti NPostomateve",
          link: "/rrjetinpostomateve",
          icon: '<i class="fas fa-project-diagram"></i>',
        },
        {
          title: "Ndihma / Kontakti",
          link: "/ndihma",
          icon: '<i class="fas fa-hands-helping"></i>',
        },
        {
          title: "Për platformën",
          link: "/per-platformen",
          icon: '<i class="fas fa-info"></i>',
        },
        {
          title: "Më shumë rreth Npostomatit",
          link: "/per-npostomat",
          icon: '<i class="fas fa-door-closed"></i>',
        },
      ],
    };
  },
};
</script>
