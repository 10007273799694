import Vue from "vue";

import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "./routes";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from 'moment'
import 'moment/locale/sq';

import store from "./store/index.js";

import "bootstrap/dist/css/bootstrap.css";
import "../node_modules/popper.js/dist/popper.min";
import "../node_modules/bootstrap/dist/js/bootstrap";
import "./assets/style/style.css";



Vue.config.productionTip = false;
Vue.prototype.moment = moment

Vue.prototype.$apiUrl = process.env.VUE_APP_API_URL

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(store);
store.dispatch("tryLogin");

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");

//Btn Wrapper
jQuery("#wrapper2").click(function() {
    jQuery(".icon").toggleClass("close");
});

//Preloader
/*var loader = document.getElementById("loader-wrapper");

const preloader = document.querySelector('.preloader');

window.addEventListener('load', () => setTimeout(function() {

    if (!preloader.style.opacity) {
        preloader.style.opacity = 1;
        preloader.style.display = "block";

    }
    if (preloader.style.opacity > 0) {
        preloader.style.opacity -= 0.1;
        preloader.style.display = "none";
    } else {
        clearInterval(setTimeout);
    }

}, 100)); 

const mq = window.matchMedia("(min-device-width: 1024px)");
var ulList = document.getElementById("ulList");

function removeBtn() {
    if (mq.matches) {
        ulList.style.display = "none";
    } else {
        ulList.style.display = "block";
    }
}
removeBtn();
*/